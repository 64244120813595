import { Component, OnInit } from '@angular/core';
import { GiftsService } from '../services/gifts.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  public gifts: any = [];
  public filteredPerson: string;
  public sortProp = 'rating';
  constructor(public giftService: GiftsService) { }

  ngOnInit(): void {
    // this.gifts = this.giftService.getGiftsLocal();
    // console.log(this.gifts);

    this.giftService.getGifts().then((returnedGifts) => {
      this.gifts = this.formatGifts(returnedGifts);
      console.log(this.gifts);
    });
  }

  markCompleted = (gift: any) => {
    this.giftService.markCompleted(gift.id).then((returnedGifts) => { this.gifts = this.formatGifts(returnedGifts); });
  }

  sortBy = (prop: string) => {
    return this.gifts.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1).reverse();
  }

  arrayOf(n: number): any[] {
    return Array(+n);
  }

  formatGifts = (giftsToFormat) => {
    giftsToFormat.forEach(gift => {
      gift.completed = !!+gift.completed;
    });

    return giftsToFormat;
  }

  isFilter= (filterName) =>{
    return this.filteredPerson == filterName;
  }
}
