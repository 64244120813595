import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OverviewComponent } from './overview/overview.component';
import { InsertGiftComponent } from './insert-gift/insert-gift.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PersonPipe } from './person.pipe';


@NgModule({
  declarations: [
    AppComponent,
    OverviewComponent,
    InsertGiftComponent,
    PersonPipe
  ],
  imports: [CommonModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
