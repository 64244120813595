import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GiftsService {

  constructor(public http: HttpClient) { }


  getGifts(): Promise<any> {
    return this.http.get('./GetGifts.php').toPromise();
  }


  markCompleted(id): Promise<any> {
    return this.http.get('./MarkCompleted.php?id=' + id).toPromise();
  }

  getGiftsLocal() {
    return of(demoData).toPromise() ;
  }
}

const demoData = [
  {
    id: '1',
    person: 'Robin',
    message: 'Portable Bluetooth + Wifi speaker. Liefst int zwart maar donkergrijs mag ook',
    item_name: 'JBL Link Portable Zwart / Grijs ',
    url: 'https://www.vandenborre.be/multiroom-of-draadloze-wifi-luidspreker/jbl-link-portable-black?utm_medium=tweakers&utm_source=cpc&utm_campaign=multiroom-of-draadloze-wifi-luidspreker',
    url2: 'https://www.coolblue.be/nl/product/854302/jbl-link-portable-zwart.html?clickref=1011lc7Ha3dh&utm_source=performancehorizon&utm_medium=affiliate&utm_ca',
    url3: null,
    img_url: 'https://cdn.pocket-lint.com/r/s/970x/assets/images/149271-speakers-review-hands-on-jbl-link-portable-initial-review-connected-and-compact-image1-prmb8samnt-jpg.webp',
    price: '120',
    rating: 5,
    price_range: '',
    buyer_name: null,
    completed: false
  },
  {
    id: '2',
    person: 'Robin',
    message: null,
    item_name: 'Xiaomi\'s 70Mai 1s Dashcam',
    url: 'https://www.bol.com/nl/p/xiaomi-70-mai-1s-wifi-fullhd-dashcam/9200000131167856/?bltgh=rh129g-e8lP5k05C5mBEIQ.1_4.9.ProductTitle',
    url2: null,
    url3: null,
    img_url: 'https://media.s-bol.com/Y7vGvX95opwK/550x398.jpg',
    price: '45',
    rating: 3,
    price_range: '',
    buyer_name: null,
    completed: true
  },
  {
    id: '4',
    person: 'Robin',
    message: 'Bord moet nummers en cijfers hebben, stukken: liefst de koning en konigin die kleuren hebben van de andere kant (zwarte queen met witte top bvb)',
    item_name: 'Schaakspel hout',
    url: 'https://www.bol.com/nl/p/sunrise-royal-maxi-schaakspel-schaakbord/9200000074307914/?bltgh=hLeQ6MCHCWuMwNABJh1FrQ.1_30.39.ProductTitle',
    url2: null,
    url3: null,
    img_url: 'https://media.s-bol.com/xQBX8YKyDpP/550x279.jpg',
    price: '45',
    rating: 2,
    price_range: '',
    buyer_name: null,
    completed: false
  },
  {
    id: '5',
    person: 'Jutta',
    message: null,
    item_name: 'Houten Espressomaker',
    url: 'https://www.degeleflamingo.com/products/little-dutch-houten-koffiezetapparaat?variant=20586268885062#utm_source=Facebook&utm_medium=social&utm_campaign=Little%20Dutch',
    url2: null,
    url3: null,
    img_url: 'https://cdn.shopify.com/s/files/1/0251/4339/products/Little_dutch-houten-koffiezet-de_gele_flamingo1_785x_crop_center.jpg?v=1593369225',
    price: '23',
    rating: null,
    price_range: '',
    buyer_name: null,
    completed: false
  },
  {
    id: '7',
    person: 'Jutta',
    message: null,
    item_name: 'Kindercamera ZooFamily',
    url: 'https://www.thezoofamily.com/collections/all',
    url2: null,
    url3: null,
    img_url: 'https://cdn.shopify.com/s/files/1/0316/3799/5656/products/The-zoofamily-kidscamera-pink-rabbit-front_2000x.jpg?v=1599220032',
    price: '50',
    rating: null,
    price_range: '',
    buyer_name: null,
    completed: true
  },
  {
    id: '8',
    person: 'Jutta',
    message: null,
    item_name: 'PlayMobile 123',
    url: 'https://www.bol.com/nl/serie/playmobil-1-2-3/9200000045845988/',
    url2: null,
    url3: null,
    img_url: 'https://media.s-bol.com/YEvzo4AJJ7w0/550x432.jpg',
    price: '30',
    rating: null,
    price_range: '',
    buyer_name: null
  },
  {
    id: '9',
    person: 'Jutta',
    message: null,
    item_name: 'Microfoon op staander',
    url: 'https://www.dreamland.be/e/nl/dl/microfon-op-stander-duet-disco-karaoke-121529',
    url2: null,
    url3: null,
    img_url: 'https://static.dreamland.be/wcsstore/ColruytB2CCAS/JPG/JPG/646x1000/std.lang.all/74/99/asset-1797499.jpg',
    price: '30',
    rating: null,
    price_range: '',
    buyer_name: null
  },
  {
    id: '10',
    person: 'Jana',
    message: null,
    item_name: 'Bamboo Haarborstel met varkenshaar',
    url: 'https://www.thebodyshop.com/nl-nl/haar/borstels-en-kammen/large-bamboo-paddle-hair-brush/p/p000065',
    url2: null,
    url3: null,
    img_url: 'https://media.thebodyshop.com/i/thebodyshop/HAIR_BRUSH_PADDLE_BAMBOO_1_INRSDPS723.jpg?$product-zoom$',
    price: '12',
    rating: null,
    price_range: '',
    buyer_name: null
  },
  {
    id: '11',
    person: 'Jana',
    message: null,
    item_name: 'Papiersnijmachine',
    url: 'https://www.bol.com/nl/l/papiersnijders/N/25925/',
    url2: null,
    url3: null,
    img_url: 'https://media.s-bol.com/rywgQqGjrDK/168x117.jpg',
    price: '35',
    rating: null,
    price_range: '',
    buyer_name: null
  }
];

