<div class="filters">
  <div class="btn" [ngClass]="isFilter('Robin')?'btn-success':'btn-dark'" (click)="filteredPerson = 'Robin'">Robin</div>
  <div class="btn" [ngClass]="isFilter('Jana')?'btn-success':'btn-dark'" (click)="filteredPerson = 'Jana'">Jana</div>
  <div class="btn" [ngClass]="isFilter('Jutta')?'btn-success':'btn-dark'" (click)="filteredPerson = 'Jutta'">Jutta</div>
  <div class="btn btn-danger" (click)="filteredPerson = ''">x</div>
</div>
<div class="col-12 col-lg-2 gift-item person-{{giftItem.person}}"
  *ngFor="let giftItem of this.gifts | person:filteredPerson" [ngClass]="{'completed':giftItem.completed}">
  <div class="gift-item-person">{{giftItem.person}}</div>
  <div class="gift-item-name">{{giftItem.item_name}} </div>
  <div class="gift-item-message">{{giftItem.message}}</div>
  <div class="gift-item-img"><img [src]="giftItem.img_url">
  </div>
  <div class="gift-item-urls">
    <a target="_blank" href="{{giftItem.url}}" title="{{giftItem.url}}">{{giftItem.url| slice:0:30}}...</a>
    <a target="_blank" *ngIf="giftItem.url2" href="{{giftItem.url2}}"
      title="{{giftItem.url2}}">{{giftItem.url2| slice:0:30}}...</a>
    <a target="_blank" *ngIf="giftItem.url3" href="{{giftItem.url3}}"
      title="{{giftItem.url3}}">{{giftItem.url3| slice:0:30}}...</a>
  </div>
  <div class="gift-item-rating">
    <span *ngFor="let bluh of this.arrayOf(giftItem.rating) "><svg width="1em" height="1em" viewBox="0 0 16 16"
        class="bi bi-star-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
      </svg></span>
  </div>
  <div class="gift-item-price"><b> ~ {{giftItem.price}}€ </b></div>
  <div class="gift-item-mark-bought" (click)="this.markCompleted(giftItem)" *ngIf="!giftItem.completed">
    <div class="checkmark"></div><span>Markeren als gekocht</span>
  </div>
  <div class="gift-item-mark-bought completed" *ngIf="giftItem.completed">
    <span>GEKOCHT</span>
  </div>
</div>